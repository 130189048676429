// Note this file should be loaded after the <xsl:call-template name="tdi-js"/>; default NOT loaded.

//tooltips.init(); // enables tooltip support for footnotes and termrefs

$(".search__button").on("click", function(){
  var search = $(".search__input").val();
  var url = $(this).data("url");
  window.location.href = url + "zoeken?q=" + search;
});

var url = document.URL; 
$("meta[property='og\\:url']").attr("content", url);
$("meta[name='dc.relation']").attr("content", url);
$("meta[name='dc.source']").attr("content", url);
$("link[rel='canonical']").attr("href", url);